import {
  ConsolidadoGeral,
  SearchConcessionaria,
} from "@/models/RelatorioHistoricoMensal";
import ApiService from "./ApiService";

/**
 * @description buscar as concessionarias baseada no id do usuario
 */
const searchConcessionaria = async (
  payload
): Promise<SearchConcessionaria[]> => {
  const { data } = await ApiService.post(
    "relatorio/optionConcessionaria",
    payload
  );
  return data;
};

const obterConcessionarias = async (
  payload
): Promise<SearchConcessionaria[]> => {
  const { data } = await ApiService.post(
    "relatorio/obterConcessionarias",
    payload
  );
  return data;
};

const faturamentoConsolidado = async (payload): Promise<ConsolidadoGeral> => {
  const { data } = await ApiService.post("relatorio/consolidadoGeral", payload);
  return data;
};

export { searchConcessionaria, faturamentoConsolidado, obterConcessionarias };
